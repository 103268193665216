import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";


const reqFolder = require.context('../assets/img/samples', true, /\.jpg$/)
const imgPATHs = reqFolder.keys().map((path) => reqFolder(path));
const erg = imgPATHs.map((p, i) => <img alt={"Door Matt " + (i + 1)} src={p} key={i}/> )

export default function OurSamples() {
  return (
    <Carousel infiniteLoop autoPlay useKeyboardArrows dynamicHeight renderIndicator={false} >
      {erg}
    </Carousel>
  );
}