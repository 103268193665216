import React from "react";

export default function OurLocation() {
    return (
        <>
            <section className="relative py-20">
                <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
                    style={{ height: '80px', transform: 'translateZ(0)' }} >
                    <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                        version="1.1" viewBox="0 0 2560 100" x="0" y="0">
                        <polygon className="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
                    </svg>
                </div>
                <div className="container mx-auto px-4" style={{ direction: 'rtl' }}>
                    <div className="items-center flex flex-wrap">

                        <div className="w-full md:w-5/12 ml-auto mr-auto px-4" data-aos="fade-left">
                            <div className="md:pr-12 text-right">
                                <div
                                    className="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-blue-400">
                                    <i className="fas fa-rocket text-xl"></i>
                                </div>
                                <h3 className="text-3xl font-semibold">علي بعد خطوات منك</h3>
                                <p className="mt-4 text-lg leading-relaxed text-gray-600">
                                    مقرنا الرئيسي يقع في منطقة مرغم الصناعية، الاسكندرية </p>
                                <ul className="list-none mt-6">
                                    <li className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span
                                                    className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3"><i
                                                        className="fas fa-cart-arrow-down"></i></span>
                                            </div>
                                            <div>
                                                <h4 className="text-gray-600 mr-2">
                                                    خدمة توصيل لأي مكان داخل الجمهورية
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span
                                                    className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3"><i
                                                        className="fa fa-tachometer-alt"></i></span>
                                            </div>
                                            <div>
                                                <h4 className="text-gray-600 mr-2">في أسرع وقت</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span
                                                    className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3"><i
                                                        className="fas fa-coins"></i></span>
                                            </div>
                                            <div>
                                                <h4 className="text-gray-600 mr-2">و أقل سعر</h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="w-full md:w-4/12 ml-auto mr-auto px-4" data-aos="fade-right">
                            <iframe width="450" height="500" title="Afnan Plastic"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6833.33592465314!2d29.850900365517088!3d31.091161795378394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f5ebafe305f03f%3A0x9bee1e5418553b52!2z2LTYp9ix2Lkg2KrYsdi52Kkg2KfZhNmG2YjYqNin2LHZitip2Iwg2KfZhNi52KzZhdmKINin2YTZgtio2YTZitipICjYo9mFINiy2LrZitmIKdiMINij2YjZhCDYp9mE2LnYp9mF2LHZitip2Iwg2KfZhNil2LPZg9mG2K_YsdmK2KnYjCDZhdi12LE!5e0!3m2!1sar!2sde!4v1605477388775!5m2!1sar!2sde"
                                frameBorder="0" style={{ border: '0' }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}