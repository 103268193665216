import React, { useState } from "react";

export default function ContactUs() {

    const [status, setStatus] = useState("SENDING");

    const handleForm = ev => {
        ev.preventDefault();
        console.log("Form has been submitted")

        const erg = ["#full-name", "#email", "#message"].map(s => ev.target.querySelector(s).value);


        const payload = {
            'name': erg[0],
            'email': erg[1],
            'message': erg[2]
        }

        console.log(payload);


        fetch("https://formspree.io/f/mknpzrlq",
            {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).catch(e => console.log(e)).then(r => r.json()).then(r => r.ok === true ? setStatus("DONE"): setStatus("400"))        
    }
    return (
        <section className="relative block py-24 lg:pt-0 bg-gray-900">
            <form onSubmit={handleForm}
                action="https://formspree.io/f/mknpzrlq"
                method="POST">
                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                                <div className="flex-auto p-5 lg:p-10" data-aos="fade-up" style={{ direction: 'rtl' }}>
                                    <h4 className="text-2xl font-semibold">
                                        هل تريد العمل معنا؟
           </h4>
                                    <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                                        إملاء النموذج و سنرد عليك خلال ٢٤ ساعة
           </p>
                                    <div className="relative w-full mb-3 mt-8">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="full-name"
                                        >
                                            اسمك
             </label>
                                        <input
                                            id="full-name"
                                            type="text"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                            placeholder="اسمك بالكامل"
                                            style={{ transition: "all .15s ease" }}
                                        />
                                    </div>

                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="email"
                                        >
                                            بريدك الالكتروني
             </label>
                                        <input
                                            id="email"
                                            type="email"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                            placeholder="بريدك الالكتروني"
                                            style={{ transition: "all .15s ease" }}
                                        />
                                    </div>

                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="message"
                                        >
                                            الرسالة
             </label>
                                        <textarea
                                            id="message"
                                            rows="4"
                                            cols="80"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                            placeholder="اكتب محتوي الرسالة..."
                                        />
                                    </div>
                                    <div className="text-center mt-6">

                                        { status !== "DONE" &&
                                            <button
                                                className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                type="submit"
                                                style={{ transition: "all .15s ease" }}
                                            >
                                                ارسل رسالة
                                            <span className="far fa-paper-plane mr-2"></span>
                                            </button>
                                        }

                                        { status === "DONE" &&
                                            <button
                                                className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                type="button"
                                                style={{ transition: "all .15s ease" }}
                                            >
                                                تم ارسال طلب
                                            <span className="fas fa-mail-bulk mr-2"></span>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    )
}